<template>
  <div class="register-card-credit" v-if="cardInfo">
    <card-header ref="header" :node="headerTitle" />
    <!-- <input type="number" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" /> -->
    <div id="content-scroll" class="content-scroll">
      <div class="content text-left">
        <label for="" class="content__label f-w3"
          >以下のクレジットカードが登録されています。</label
        >
        <div class="content__info f-w3 text-left">
          <div for="" class="content__info__label f-w3">
            登録済クレジットカード
          </div>
          <div class="content__info__card-number d-flex flex-column">
            <label for="">クレジットカード番号</label>
            <p>* * * * * * * * * * * * * {{ cardInfo.last3 }}</p>
          </div>
          <div class="content__info__expiration-date d-flex flex-column">
            <label for="">有効期限</label>
            <p>{{ cardInfo.exp_year }}年{{ cardInfo.exp_month }}</p>
          </div>
        </div>
        <div class="content__tutorial f-w3">
          登録情報を変更する場合は「変更」ボタンを、登録情報を削除する場合は「削除」ボタンを押してください。
        </div>
        <div class="content__action d-flex f-w3 justify-content-center">
          <div
            :class="
              `
              d-flex
              justify-content-center
              flex-column
              align-items-center
              content-delete cursor-pointer 
              ${!cardInfo.is_show ? 'disabled' : ''}`
            "
            @click.prevent="deleteCard"
          >
            <div
              class="delete d-flex justify-content-center align-items-center"
            >
              <img
                src="@/assets/image/credit_card/icon-credit-card.svg"
                alt=""
              />
            </div>
            <p class="text-credit-card">削除</p>
          </div>
          <div
            class="d-flex justify-content-center flex-column align-items-center cursor-pointer"
            @click="nextPageRegister"
          >
            <div
              class="change d-flex justify-content-center align-items-center"
            >
              <img src="@/assets/image/credit_card/icon-reload.svg" alt="" />
            </div>
            <p class="text-credit-card">変更</p>
          </div>
          <!-- <button
            type="submit"
            class="content__action__change"
            @click="nextPageRegister"
          >
            変更
          </button> -->
        </div>
        <div class="redirect-my-page" v-if="$route.query.identify">
          <button
            class="redirect-my-page__btn"
            @click="$router.push({ name: 'MenMyPageIdentifyProfile' })"
          >
            身分証登録に進む
          </button>
        </div>
        <div class="redirect-my-page">
          <button
            class="redirect-my-page__btn"
            v-if="!$route.query.identify"
            @click="redirectMypage()"
          >
            プロフィールに戻る
          </button>
          <div
            v-else
            class="text-center text-redirect-my-page"
            @click="redirectMypage()"
          >
            プロフィールに戻る
          </div>
        </div>
      </div>
    </div>
    <card-footer ref="footer" />
  </div>
</template>

<script>
import CardHeader from "@/components/CardHeader";
import CardFooter from "@/components/CardFooter.vue";
import { mapGetters } from "vuex";
export default {
  name: "MyPage",
  components: {
    "card-header": CardHeader,
    "card-footer": CardFooter
  },
  metaInfo() {
    return {
      title: "クレジットカード設定",
      titleTemplate: "%s | PreGo"
    };
  },
  computed: {
    ...mapGetters({
      cardInfo: "payment-stripe/cardInfo"
    })
  },
  async mounted() {
    this.$root.$refs.loading.start();
    await this.$store.dispatch("payment-stripe/getCreditCard");
    if (!this.cardInfo) this.$router.push({ name: "MenRegisterCardCredit" });
    this.$root.$refs.loading.finish();
  },
  data() {
    return {
      headerTitle: {
        text: "クレジットカードの情報・登録",
        isShowTitle: true,
        isShowLogo: false,
        back: null,
        backTo: "/my-page/setting-account"
      }
    };
  },
  methods: {
    nextPageRegister() {
      this.$root.$refs.loading.start();
      this.$router.push({ name: "MenRegisterCardCredit" });
      this.$root.$refs.loading.finish();
    },
    async deleteCard() {
      if (!this.cardInfo.is_show) {
        return;
      }
      this.$root.$refs.loading.start();
      await this.$store
        .dispatch("payment-stripe/deleteCreditCard")
        .then(() => {
          this.$root.$refs.loading.finish();
          this.$router.push({ name: "MenMyPage" });
          this.$toast(
            "クレジットカードを正常に削除しました",
            "通知",
            "success"
          );
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
        });
    },
    redirectMypage() {
      this.$router.push({ path: "/my-page/" });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/men/infoCardCredit.scss";
@import "~@/assets/scss/_fontFamily.scss";

.content-scroll {
  // overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 40px;
  padding-bottom: 100px;
  // height: calc(100vh - 108px);
}

@media screen and (min-width: 1200px) {
  // .content-scroll {
  //   // height: calc(100vh - 152px);
  // }
}

.content-delete {
  margin-right: 36px;
}
.content-delete.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.text-redirect-my-page {
  margin-top: 15px;
  cursor: pointer;
}
.delete {
  width: 38px;
  height: 38px;
  border-radius: 12px;
  box-shadow: none;
  outline: none;
  background-color: #f14e4e;
}
.change {
  width: 38px;
  height: 38px;
  border-radius: 12px;
  box-shadow: none;
  outline: none;
  background-color: #464d77;
}
.text-credit-card {
  font-size: 14px;
  line-height: 14px;
  margin-top: 5px;
  color: #9c9c9c;
  margin-bottom: 0;
}
@media screen and (min-width: 1080px) {
  .content-delete {
    margin-right: 58px;
  }
  .delete {
    width: 64px;
    height: 64px;
    border-radius: 20px;
  }
  .text-redirect-my-page {
    font-size: 20px;
    margin-top: 25px;
  }
  img {
    width: 27px;
    height: 30px;
  }
  .change {
    width: 64px;
    height: 64px;
    border-radius: 20px;
  }
  .text-credit-card {
    font-size: 16px;
    line-height: 16px;
    margin-top: 10px;
  }
}
</style>
